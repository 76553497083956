<template>
  <v-container class="mb-3 mt-3">
    <v-card class="mb-3">
      <v-card-text>
        <h2 class="text-h4 font-weight-bold">Contact US</h2>
        <v-divider></v-divider>
      </v-card-text>

      <v-row class="contact-page" no-gutters>
        <v-col cols="12" md="4" lg="3">
          <v-card-text>
            <div class="contact-page__section-title">
              <v-card-text class="pt-1 pb-1 pl-0">
                <v-icon class="mr-2"> mdi-home</v-icon>
                {{ $apps.address }}
              </v-card-text>
              <v-divider class="mb-2 mt-1"></v-divider>
              <v-card-text class="pt-1 pb-1 pl-0">
                <v-icon class="mr-2"> mdi-phone</v-icon>
                {{ $apps.phone }}
              </v-card-text>
              <v-divider class="mb-2 mt-1"></v-divider>
              <v-card-text class="pt-1 pb-1 pl-0">
                <v-icon class="mr-2"> mdi-earth</v-icon>
                {{ $apps.web }}
              </v-card-text>
              <v-divider class="mb-2 mt-1"></v-divider>
              <v-card-text class="pt-1 pb-1 pl-0">
                <v-icon class="mr-2"> mdi-email</v-icon>
                {{ $apps.email }}
              </v-card-text>
            </div>
          </v-card-text>
          <v-card-text>
            <div class="google-map">
              <gmaps-map>
                <gmaps-marker :position="mapCenter"></gmaps-marker>
              </gmaps-map>
            </div>
          </v-card-text>
        </v-col>

        <v-col cols="12" md="8" lg="9">
          <v-card-text>
            <div class="contact-page__contact-form">

              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="name" :rules="nameRules" label="Name" required outlined />
                    </v-col>

                    <v-col cols="12">
                      <v-text-field v-model="email" :rules="emailRules" label="Email" required outlined />
                    </v-col>

                    <v-col cols="12">
                      <v-text-field v-model="subject" label="Subject" required outlined />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea v-model="message" label="Message" outlined />
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                        :disabled="submitting"
                        :loading="submitting"
                        class="ma-2 white--text"
                        color="primary"
                        large
                      >
                        Send
                        <v-icon dark right> mdi-send</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'Contact',
  data: () => ({
    mapCenter: { lat: -27, lng: 153 },
    submitting: false,
    valid: false,
    name: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => v.length <= 10 || 'Name must be less than 10 characters',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    subject: '',
    message: ''
  }),
};
</script>
<style lang="scss">
.gmaps-map {
  min-height: 400px;
}
</style>
